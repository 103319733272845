// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { darken, lighten, PaletteColorOptions } from '@mui/material/styles';

// ==============================|| PRESET THEME - DEFAULT ||============================== //

export default function Default(colors: PalettesProps): PaletteThemeProps {
  const { red, gold, cyan, green, grey, purple } = colors;
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10]
  };
  const purpleColors: PaletteColorOptions = {
    0: purple[0],
    50: purple[1],
    100: purple[2],
    200: purple[3],
    300: purple[4],
    400: purple[5],
    500: purple[5],
    600: purple[7],
    700: purple[8],
    800: purple[9],
    900: purple[10]
  }
  const contrastText = '#fff';

  return {
    primary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[600]!,
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[100],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[100]
    },
    secondary: {
      lighter: purpleColors[0],
      100: purpleColors[100],
      200: purpleColors[200],
      light: purpleColors[300],
      400: purpleColors[400],
      main: purpleColors[500]!,
      600: purpleColors[600],
      dark: purpleColors[700],
      800: purpleColors[800],
      darker: purpleColors[900],
      A100: purpleColors[100],
      A200: purpleColors.A400,
      A300: purpleColors.A700,
      contrastText: purpleColors[300]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: lighten("#c0fc84", 0.5),
      light: "#c0fc84",
      main: darken('#c0fc84', 0.1),
      dark: "#1dff6ad9",
      darker: darken('#c0fc84', 0.6),
      contrastText
    },
    grey: greyColors,
    purple: purpleColors
  };
}
