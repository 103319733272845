import { createRoot } from 'react-dom/client';

// project import
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER ||============================== //

root.render(
  <App />
);
