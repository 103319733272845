// material-ui
import { CircularProgress, Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularWithPath from './@extended/progress/CircularWithPath';

// ==============================|| Loader ||===================    =========== //

export default function Loader() {
  const theme = useTheme();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        position: 'fixed',      // Fixed position to cover the entire screen
        top: 0,
        left: 0,
        width: '100vw',         // Full width of the screen
        height: '100vh',        // Full height of the screen
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black shadow with slight transparency
        zIndex: 9999,           // High z-index to ensure it overlays everything
      }}
    >
      <CircularWithPath sx={{ color: theme.palette.secondary.main }} />
    </Stack>
  );
}