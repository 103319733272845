// material-ui
import { Theme } from '@mui/material/styles';

// third-party
import { merge } from 'lodash';

// project import
import Alert from './Alert';
import AlertTitle from './AlertTitle';
import Button from './Button';
import ButtonBase from './ButtonBase';
import ButtonGroup from './ButtonGroup';
import CardContent from './CardContent';
import FormHelperText from './FormHelperText';
import IconButton from './IconButton';
import InputBase from './InputBase';
import InputLabel from './InputLabel';
import Link from './Link';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import LoadingButton from './LoadingButton';
import OutlinedInput from './OutlinedInput';
import Typography from './Typography';
import Chip from './Chip';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Alert(theme),
    AlertTitle(),
    Button(theme),
    ButtonBase(),
    ButtonGroup(),
    CardContent(),
    Chip(theme),
    FormHelperText(),
    IconButton(theme),
    InputBase(),
    InputLabel(theme),
    Link(),
    ListItemButton(theme),
    ListItemIcon(theme),
    LoadingButton(),
    OutlinedInput(theme),
    Typography()
  );
}
