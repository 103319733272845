export const APP_DEFAULT_PATH = '/dashboard';
export const GUEST_APP_DEFAULT_PATH = '/login';

export enum ThemeMode {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum MenuOrientation {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export enum ThemeDirection {
    LTR = 'ltr',
    RTL = 'rtl'
}