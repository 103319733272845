import React, { createContext, useEffect } from 'react';
import axiosServices from 'utils/axios';

import { LOGIN, LOGOUT, REGISTER } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';

import Loader from 'components/Loader';
import { AuthProps } from '@/types/auth';
import { UserProfile } from '@/types/auth';

const axios = axiosServices;
const CURRENT_SESSION = 'api/v1/session'
const REGISTER_URL = '/users'
const LOGIN_URL = '/users/sign_in'
const LOGOUT_URL = '/users/sign_out'
const FORGOT_PASSWORD_URL = '/users/password'
const RESET_PASSWORD_URL = '/users/password'
const GOOGLE_SIGN_IN_URL = '/users/auth/google_oauth2'

const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
}

type AuthContextType = {
  isLoggedIn: boolean;
  isInitialized?: boolean;
  user?: UserProfile | null | undefined;
  logout: () => void;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (resetPasswordtoken: string, password: string) => Promise<void>;
  googleSignIn: VoidFunction;
  fetchUser: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(authReducer, initialState);
  const fetchUser = async () => {
    try {
      const response = await axios.get(CURRENT_SESSION);
      const { authenticated, user } = response.data;  
      if (authenticated) {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }

    } catch (err) {
      console.error(err);
      dispatch({
        type: LOGOUT
      });
    }
  }
  useEffect(() => {
    fetchUser();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(LOGIN_URL, { "user": { email, password } });
    const user = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email: string, password: string) => {
    const response = await axios.post(REGISTER_URL, {
      user: {
        email,
        password,
    }});
    let user = response.data;

    dispatch({
      type: REGISTER,
      payload: {
        isLoggedIn: true,
        isInitialized: true,
        user: user
      }
    });
  }

  const logout = async () => {
    const response = await axios.delete(LOGOUT_URL);
    dispatch({
      type: LOGOUT,
      payload: {
        isLoggedIn: false
      }
    });
  };
  const forgotPassword = async (email: string) => {
    const response = await axios.post(FORGOT_PASSWORD_URL, { "user": {email} });
  }

  const resetPassword = async (resetPasswordToken: string, password: string) => {
    const response = await axios.put(RESET_PASSWORD_URL, {
      user: {
        reset_password_token: resetPasswordToken,
        password: password
      }
    });
  }

  const googleSignIn = () => {
    window.location.href = GOOGLE_SIGN_IN_URL;
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <AuthContext.Provider value={{ ...state, login, logout, register, forgotPassword, resetPassword, googleSignIn, fetchUser }}>{children}</AuthContext.Provider>;
}

export default AuthContext;

