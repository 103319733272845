import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';

// project import
import { APP_DEFAULT_PATH, GUEST_APP_DEFAULT_PATH } from 'config';
//import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import useAuth from '@/hooks/useAuth';

// ==============================|| GUEST GUARD ||============================== //

export default function GuestGuard({ children }: GuardProps) {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : APP_DEFAULT_PATH, {
        state: {
          from: ''
        },
        replace: true
      });
    }
    else if (location.pathname == '/' || location.pathname == '') {
      navigate('/login', {
        state: { from: location.pathname },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
}
