import AuthGuard from "@/utils/route-guard/AuthGuard";
import { Outlet } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';

export default function DashboardLayout() {
  const { logout } = useAuth();
  const handleLogout = async () => {
    await logout();
  }
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  );
}
