// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetDarkPalettes, PalettesProps } from '@ant-design/colors';

// project import
import ThemeOption from './theme';
import { ThemeMode } from 'config';

// types
import { PaletteThemeProps } from 'types/theme';
import { PresetColor } from 'types/config';

// ==============================|| DEFAULT THEME - PALETTE ||============================== //

export default function Palette(mode: ThemeMode, presetColor: PresetColor) {
  //CURRENTLY WE ONLY HAVE DARK THEME, MAYBE LATER WE WILL HAVE LIGHT ONE
  const colors: PalettesProps = presetDarkPalettes;

  let greyPrimary = [
    '#F3F4F6', // lightest
    '#E5E7EB', // lighter
    '#D1D5DB', // light
    '#9CA3AF', // neutral
    '#6B7280', // dark neutral
    '#4B5563', // darker neutral
    '#374151', // dark
    '#1F2937', // darker
    '#1C2632', // even darker
    '#181C24', // almost darkest
    '#111827'  // darkest
  ];

  const customPurple = [
    '#D8B5FF',
    '#D1B6FF',
    '#D1B6FF',
    '#C084FC',
    '#C084FC',
    '#C549FF',
    '#9A3DFF',
    '#7A2BFF',
    '#5C00CC',
    '#4B00A0',
    '#111827'
  ];
  
  colors.grey = [...greyPrimary];
  colors.purple = [...customPurple];

  const paletteColor: PaletteThemeProps = ThemeOption(colors, presetColor, mode);

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      ...paletteColor,
      // text: {
      //   primary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900]!, 0.87) : paletteColor.grey[700],
      //   secondary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900]!, 0.45) : paletteColor.grey[500],
      //   disabled: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900]!, 0.1) : paletteColor.grey[400]
      // },
      action: {
        disabled: paletteColor.grey[300]
      },
      // divider: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900]!, 0.05) : paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[100],
        default: paletteColor.grey[500]
      }
    }
  });
}
