// material-ui
import { alpha, Theme } from '@mui/material/styles';

// project import
import getColors from 'utils/getColors';
import getShadow from 'utils/getShadow';

// types
import { ButtonVariantProps, ExtendedStyleProps } from 'types/extended';
import { borderRadius, fontWeight, height } from '@mui/system';
import { light } from '@mui/material/styles/createPalette';

// ==============================|| BUTTON - COLORS ||============================== //

interface ButtonStyleProps extends ExtendedStyleProps {
  variant: ButtonVariantProps;
}

function getColorStyle({ variant, color, theme }: ButtonStyleProps) {
  const colors = getColors(theme, color);
  const { lighter, light, main, dark, darker, contrastText } = colors;

  switch (variant) {
    case 'contained':
      return {
        color: darker,
        backgroundColor: main,
        '&:hover': {
          backgroundColor: darker,
          color: lighter
        },
      };
    case 'outlined':
      return {
        borderColor: light,
        color: contrastText,
        '&:hover': {
          color: lighter,
          backgroundColor: 'transparent',
          borderColor: lighter
        },
      };
    default:
      return {
        color: dark,
        '&:hover': {
          color: darker,
          backgroundColor: lighter
        },
      };
  }
}

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme: Theme) {
  const primaryOutlined = getColorStyle({ variant: 'outlined', color: 'secondary', theme });
  const primaryContained = getColorStyle({ variant: 'contained', color: 'secondary', theme });

  const disabledStyle = {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[200]
    }
  };
  const iconStyle = {
    '&>*:nth-of-type(1)': {
      fontSize: '18px',
      fontWeight: 600
    }
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: 0,
          height: "50px",
          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            borderRadius: 0,
            opacity: 0,
            transition: 'all 0.5s'
          },
          '&:active::after': {
            position: 'absolute',
            borderRadius: 0,
            left: 0,
            top: 0,
            opacity: 1,
            transition: '0s'
          }
        },
        contained: {
          ...primaryContained
        },
        outlined: {
          ...primaryOutlined
        },
        text: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        },
        endIcon: {
          ...iconStyle
        },
        startIcon: {
          ...iconStyle
        },
        sizeExtraSmall: {
          minWidth: 56,
          fontSize: '0.625rem',
          padding: '2px 8px'
        }
      }
    }
  };
}
