// material-ui
import { Theme } from '@mui/material/styles';

// project import
import getColors from 'utils/getColors';

// types
import { ExtendedStyleProps } from 'types/extended';
import { borderColor } from '@mui/system';

// ==============================|| CHIP - COLORS ||============================== //

// function getColor({ color, theme }: ExtendedStyleProps) {
//   const colors = getColors(theme, color);
//   const { dark, light, lighter } = colors;

//   return {
//     '&.Mui-focusVisible': {
//       outline: `2px solid ${light}`,
//       outlineOffset: 2
//     }
//   };
// }

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme: Theme) {
  const colors = getColors(theme, "primary");
  const { light, lighter, main, dark, darker } = colors;

  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          borderColor: light,
          '&:active': {
            boxShadow: 'none'
          },
          '&.MuiChip-filled': {
            backgroundColor: lighter,
            color: darker
          }
        },
      }
    }
  };
}
